@import "~bootstrap/scss/bootstrap";


$body-bg: var(--bs-dark);
$body-color: whitesmoke;
// customize bootstrap here - then:


@import "./app.scss"


